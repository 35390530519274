<template>
  <a-spin :spinning="isFetching">
    <a-calendar ref="calendar" @panelChange="onPanelChange">
      <template #headerRender="{ value, onChange }">
        <div class="calendar-header">
          <button
            class="calendar-prev"
            :disabled="isFetching"
            @click.prevent="onPrev(value, onChange)"
          >
            <CaretLeftFilled />
          </button>
          <div class="selected-month">
            {{ value.format('MMMM YYYY') }}
          </div>
          <button
            class="calendar-next"
            :disabled="isFetching"
            @click.prevent="onNext(value, onChange)"
          >
            <CaretRightFilled />
          </button>
        </div>
      </template>
      <template #dateFullCellRender="{ current: value }">
        <RoomAssignmentCalendarCell
          :date-obj="value"
          :date="value.format('YYYY-MM-DD')"
          :value="roomAssignmentData"
          :position="value.day()"
          :last-position="6"
        />
      </template>
    </a-calendar>
  </a-spin>
</template>

<script>
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import RoomAssignmentCalendarCell from '@/views/room-assignment/components/RoomAssignmentCalendarCell';

export default {
  name: 'RoomAssignmentCalendar',
  components: {
    RoomAssignmentCalendarCell,
    CaretLeftFilled,
    CaretRightFilled,
  },
  props: {
    propertyId: {
      type: [String, Number],
      required: true,
    },
    roomId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      selectedDay: undefined,
      list: undefined,
      isFetching: false,
    };
  },
  computed: {
    roomAssignmentData() {
      return this.list && this.list.length ? this.list[0] : null; // The list returns only the currently selected room
    },
  },
  watch: {
    roomId: {
      handler(nv) {
        if (nv && this.selectedDay) {
          this.debounceFetchData();
        }
      },
    },
  },
  created() {
    dayjs.locale(this.$i18n.locale);
    this.selectedDay = dayjs();
    this.fetchData();
  },
  methods: {
    onPrev(value, cb) {
      cb(value.clone().subtract(1, 'M'));
    },
    onNext(value, cb) {
      cb(value.clone().add(1, 'M'));
    },
    onPanelChange(date) {
      this.selectedDay = date;
      this.debounceFetchData();
    },
    fetchData() {
      if (!this.selectedDay) return;
      this.dateFrom = this.selectedDay.startOf('month').format('YYYY-MM-DD');
      this.dateTo = this.selectedDay.endOf('month').format('YYYY-MM-DD');
      this.list = undefined;
      this.isFetching = true;
      const payload = {
        dateFrom: dayjs(this.dateFrom).subtract(1, 'month').format('YYYY-MM-DD'),
        dateTo: dayjs(this.dateTo).add(1, 'month').format('YYYY-MM-DD'),
        propertyId: this.propertyId,
        roomId: this.roomId,
        status: 'requested,rejected,assigned,in-progress,finished',
        reservation: 'booked,blocked',
      };
      this.$store
        .dispatch('calendar/list', { params: payload })
        .then((resp) => {
          this.list = resp.list;
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    // eslint-disable-next-line
    debounceFetchData: debounce(function () {
      this.fetchData();
    }, 600),
  },
};
</script>

<style lang="scss" scoped>
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $tab-head-bg;
  color: $white;
  > * {
    padding: 8px;
  }

  button {
    color: $white;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
    }
  }
}
:deep {
  .ant-fullcalendar-calendar-body {
    padding: 0;
  }
  .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
    background: #f5f5f5;
    padding: 6px 0 6px 3px;
    text-align: left;
  }
  .ant-fullcalendar-cell {
    border: 1px solid #dcdcdc;
  }
  .ant-fullcalendar-last-month-cell .date {
    color: #888888;
  }
}
</style>
