<template>
  <ModalWide :mask="mask">
    <template #default="{ hide }">
      <RoomAssignmentForm
        :draggable="draggable"
        :value="{}"
        @close="
          () => {
            hide();
            fetchData();
          }
        "
        @cancel="hide"
      />
    </template>
    <template #handler="{ show }">
      <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
        {{ $t('Add Room Assignment') }}
      </a>
    </template>
  </ModalWide>
</template>

<script>
import ModalWide from '@/components/ModalWide';
import RoomAssignmentForm from '@/views/room-assignment/components/RoomAssignmentForm';

export default {
  name: 'RoomAssignmentAddModal',
  components: {
    ModalWide,
    RoomAssignmentForm,
  },
  props: {
    mask: {
      type: Boolean,
    },
    draggable: {
      type: Boolean,
    },
  },
  emits: ['close'],
  methods: {
    fetchData() {
      this.$emit('close');
    },
  },
};
</script>

<i18n>
{
  "en": {
    "Add Room Assignment": "Add Room Assignment"
  },
  "ja": {
    "Add Room Assignment": "新規清掃の登録追加する"
  }
}
</i18n>
