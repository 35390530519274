import dayjs from 'dayjs';
import findLastIndex from 'lodash/findLastIndex';
import findIndex from 'lodash/findIndex';
import { getFullname } from '@/utils/users';

export default {
  computed: {
    assignment() {
      if (this.value && this.value.roomAssignments) {
        return this.value.roomAssignments.find((item) => item.cleaningDate === this.date);
      }
      return null;
    },
    hasAssignment() {
      return !!this.assignment;
    },
    housekeeperCompany() {
      return this.hasAssignment && this.assignment.partner ? this.assignment.partner : null;
    },
    housekeeperCompanyName() {
      return this.housekeeperCompany ? this.housekeeperCompany.name : this.$t('N.A.');
    },
    housekeeper() {
      return this.hasAssignment && this.assignment.user ? this.assignment.user : null;
    },
    housekeeperName() {
      if (!this.showName) {
        return '';
      }

      return this.housekeeper
        ? getFullname({
          fname: this.housekeeper.firstName,
          lname: this.housekeeper.lastName,
        })
        : this.$t('N.A.');
    },
    guest() {
      if (this.value && this.value.reservedRooms) {
        const guest = this.value.reservedRooms.find((item) => {
          if (item.reservation) {
            const { reservation, propertyId, roomNumber } = item;
            const checkout = dayjs(reservation.checkout).subtract(1, 'day');
            return (
              (propertyId === this.value.room.propertyId)
              && (dayjs(this.date).isSame(reservation.checkin)
                || dayjs(this.date).isSame(checkout)
                || (dayjs(this.date).isAfter(reservation.checkin)
                  && dayjs(this.date).isBefore(checkout)))
              && (
                !this.value.property.useRoomType
                || (this.value.property.useRoomType && roomNumber)
              )
            );
          }

          return false;
        });
        if (guest) {
          const {
            reservation, guests, totalGuest, roomCode, isSameGuest, hasExcessGuests,
          } = guest;
          return {
            id: reservation.id,
            checkin: reservation.checkin,
            checkout: reservation.checkout,
            guestName:
              guests && guests[0]
                ? `${guests[0].surname || guests[0].kanjiName || ''}`
                : totalGuest !== undefined
                  ? `${totalGuest} guest(s)`
                  : '-',
            roomCode,
            isSameGuest,
            hasExcessGuests,
          };
        }
      }
      return null;
    },
    guestDays() {
      return this.hasGuest ? dayjs(this.guest.checkout).diff(this.guest.checkin, 'days') + 1 : 0;
    },
    hasGuest() {
      return !!this.guest;
    },
    canShowGuest() {
      const reservationFilters = this.$route.query.reservation;
      return !this.hasSameGuest
        || !reservationFilters
        || reservationFilters.includes('same-guest');
    },
    canShowExcessGuest() {
      const reservationFilters = this.$route.query.reservation;
      return !this.hasExcessGuests
        || !reservationFilters
        || reservationFilters.includes('excess-guests');
    },
    hasSameGuest() {
      return this.guest?.isSameGuest;
    },
    hasExcessGuests() {
      return this.guest?.hasExcessGuests;
    },
    guestName() {
      if (!this.showName) {
        return '';
      }

      return (this.guest && this.guest.guestName) || this.$t('N.A.');
    },
    isGuestFirstDate() {
      return this.hasGuest && dayjs(this.date).isSame(this.guest.checkin);
    },
    isGuestLastDate() {
      if (this.hasGuest) {
        const checkout = dayjs(this.guest.checkout).subtract(1, 'day');
        return dayjs(this.date).isSame(checkout);
      }

      return false;
    },
    /**
     * only one reservation on that day, and it is the guest's checkout date.
     * @returns {null|{checkin: string, id: string, checkout: string, guestName: string}}
     */
    checkoutGuest() {
      if (this.value && this.value.reservedRooms) {
        const guest = this.value.reservedRooms.find((item) => {
          if (item.reservation) {
            const { reservation, propertyId, roomNumber } = item;
            const checkout = dayjs(reservation.checkout);
            return (
              (propertyId === this.value.room.propertyId)
                && (dayjs(this.date).isSame(checkout))
              && (
                !this.value.property.useRoomType
                || (this.value.property.useRoomType && roomNumber)
              )
            );
          }
          return false;
        });
        if (guest) {
          if (guest) {
            const { reservation, guests, totalGuest } = guest;
            return {
              id: reservation.id,
              checkin: reservation.checkin,
              checkout: reservation.checkout,
              guestName:
                  guests && guests[0]
                    ? `${guests[0].surname || guests[0].kanjiName || ''}`
                    : totalGuest !== undefined
                      ? `${totalGuest} guest(s)`
                      : '-',
            };
          }
        }
      }
      return null;
    },
    checkoutGuestName() {
      if (!this.showName) {
        return '';
      }

      return (this.checkoutGuest && this.checkoutGuest.guestName) || this.$t('N.A.');
    },
    assignmentForm() {
      return {
        id: this.hasAssignment ? this.assignment.id : undefined,
        partnerId: this.hasAssignment && this.housekeeperCompany ? this.assignment.partnerId : null,
        userId: this.hasAssignment ? this.assignment.userId : undefined,
        propertyId: this.value ? this.value.property.newPropertyCode : undefined,
        roomId: this.value ? this.value.room.id : undefined,
        cleaningDate: this.hasAssignment ? this.assignment.cleaningDate : this.date,
        cleaningTime: this.hasAssignment ? this.assignment.cleaningTime : undefined,
        status: this.hasAssignment ? this.assignment.status : undefined,
        remarks: this.hasAssignment ? this.assignment.remarks : undefined,
      };
    },
    blockedDatesAscending() {
      if (this.value && this.value.blockedDates) {
        const dates = [...this.value.blockedDates];
        const sorted = dates.sort(
          (a, b) => new Date(a.blockedDate).getTime() - new Date(b.blockedDate).getTime(),
        );
        return sorted.map((blocked, idx) => {
          const opts = {
            showText: false,
            isFirst: false,
            isLast: false,
          };
          if (idx === 0) {
            opts.showText = true;
            opts.isFirst = true;
          } else if (dayjs(blocked.blockedDate).diff(sorted[idx - 1].blockedDate, 'days') > 1) {
            opts.showText = true;
            opts.isFirst = true;
          }

          if (sorted[idx + 1]) {
            if (dayjs(sorted[idx + 1].blockedDate).diff(blocked.blockedDate, 'days') > 1) {
              opts.isLast = true;
            }
          }

          if (idx + 1 === dates.length) {
            opts.isLast = true;
          }

          return {
            ...blocked,
            ...opts,
          };
        });
      }
      return null;
    },
    blockedDate() {
      if (this.blockedDatesAscending) {
        const selectedDate = this.blockedDatesAscending
          .find((item) => item.blockedDate === this.date);
        if (selectedDate) {
          const selectedDateIdx = this.blockedDatesAscending
            .findIndex((item) => item.blockedDate === this.date);
          let firstDateIdx = selectedDateIdx;
          let lastDateIdx = selectedDateIdx;
          if (!selectedDate.isFirst) {
            firstDateIdx = findLastIndex(
              this.blockedDatesAscending.slice(0, selectedDateIdx),
              'isFirst',
            );
          }
          if (!selectedDate.isLast) {
            lastDateIdx = findIndex(this.blockedDatesAscending, 'isLast', selectedDateIdx);
          }
          const block = this.blockedDatesAscending.slice(firstDateIdx, lastDateIdx + 1);
          return {
            ...selectedDate,
            firstDate: block[0].blockedDate,
            lastDate: block[block.length - 1].blockedDate,
            ids: block.map((item) => item.id),
          };
        }
        return null;
      }
      return null;
    },
    isUnavailableTextShowed() {
      return this.blockedDate && this.blockedDate.showText;
    },
    hasBlockedDate() {
      return !!this.blockedDate;
    },
    isUnavailableStart() {
      return this.hasBlockedDate && this.blockedDate.isFirst;
    },
    isUnavailableEnd() {
      return this.hasBlockedDate && this.blockedDate.isLast;
    },
    blockedDateBeforeDateFrom() {
      if (!this.blockedDatesAscending) {
        return false;
      }
      if (this.position === 0 || this.isUnavailableEnd) {
        const previousDay = dayjs(this.date).subtract(1, 'day').format('YYYY-MM-DD');
        const previousBlockedDate = this.blockedDatesAscending.find(
          (item) => item.blockedDate === previousDay,
        );

        if (previousBlockedDate) {
          return previousBlockedDate;
        }
      }

      return false;
    },
    hasBlockedDateBeforeDateFrom() {
      return this.blockedDateBeforeDateFrom && this.blockedDateBeforeDateFrom.isLast;
    },
    blockedDateForm() {
      return {
        id: this.blockedDate ? this.blockedDate.id : undefined,
        propertyId: this.value ? this.value.property.newPropertyCode : undefined,
        roomId: this.value ? this.value.room.id : undefined,
        blockedDate: this.blockedDate ? this.blockedDate.blockedDate : undefined,
        reason: this.blockedDate ? this.blockedDate.reason : undefined,
      };
    },
    blockedDatePopupData() {
      if (this.hasBlockedDateBeforeDateFrom) {
        return this.blockedDateBeforeDateFrom;
      }

      return this.blockedDate;
    },
    showName() {
      return this.$store.state.calendar.showName;
    },
  },
};
