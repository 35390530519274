<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <InputProperty
        v-model:value="propertyId"
        displayed-text="abbreviation"
        style="min-width: 200px"
        @change="debounceQuery"
      />
    </div>
    <div class="column is-narrow">
      <InputRoom
        v-model:value="roomId"
        :property-id="propertyId"
        style="min-width: 200px"
        @change="debounceQuery"
      />
    </div>
    <div class="column is-narrow">
      <a-range-picker
        v-model:value="range"
        style="width: 100%"
        :size="size"
        :format="dateFormat"
        @change="debounceQuery"
      >
        <a-input
          :value="rangeString"
          :placeholder="$t(`Assignment date range`)"
          :size="size"
          readonly
        >
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </div>
    <div class="column is-narrow">
      <a-select
        v-model:value="status"
        :placeholder="$t('Status')"
        style="min-width: 200px; width: 100%"
        :size="size"
        @change="debounceQuery"
      >
        <a-select-option v-for="(v, k) in statusOptions" :key="k" :value="v">
          {{ $t(v) }}
        </a-select-option>
      </a-select>
    </div>
    <div class="column">
      <a-button
        v-show="propertyId || roomId || status || range.length"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { CalendarFilled } from '@ant-design/icons-vue';
import debounce from 'lodash/debounce';
import InputRoom from '@/components/InputRoom';
import InputProperty from '@/components/InputProperty';
import { cleanObject } from '@/utils/util';
import { STATUS } from '@/config/room-assignments';

export default {
  name: 'RoomAssignmentFilter',
  components: {
    CalendarFilled,
    InputRoom,
    InputProperty,
  },
  props: {
    size: {
      type: Boolean,
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      propertyId: undefined,
      roomId: undefined,
      range: [],
      status: undefined,
      statusOptions: STATUS,
    };
  },
  computed: {
    rangeString() {
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: this.dateFormat })} ~ ${this.$filters.date(
          this.range[1],
          { format: this.dateFormat },
        )}`
        : '';
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      const range = { dateFrom: undefined, dateTo: undefined };

      if (this.range.length) {
        range.dateFrom = this.$filters.date(this.range[0], { format: dateFormat });
        range.dateTo = this.$filters.date(this.range[1], { format: dateFormat });
      }

      return range;
    },
  },
  mounted() {
    const propertyId = this.property ? this.property.newPropertyCode : undefined;

    this.propertyId = propertyId || this.$route.query.propertyId || undefined;
    this.roomId = this.$route.query.roomId || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo]
      : [];
    this.status = this.$route.query.status || undefined;
  },
  methods: {
    // eslint-disable-next-line func-names
    debounceQuery: debounce(async function () {
      const query = cleanObject({
        ...this.$route.query,
        propertyId: this.propertyId,
        roomId: this.roomId,
        ...this.formattedRange,
        status: this.status,
        page: 1, // force page 1
      });
      await this.$router.push({ query });
      this.$emit('filter');
    }, 800),
    handleClear() {
      this.propertyId = undefined;
      this.roomId = undefined;
      this.range = [];
      this.status = undefined;
      this.debounceQuery();
    },
  },
};
</script>

<i18n>
{
  "en": {
    "Assignment date range": "Assignment date range",
  },
  "ja": {
    "Assignment date range": "割り当ての日付範囲",
  }
}
</i18n>
