{
  "en": {
    "Housekeeping company": "Housekeeping company",
    "Housekeeper": "Housekeeper",
    "days": "day(s)",
    "Property": "Property",
    "Room": "Room",
    "Date": "Date",
    "Cleaning time": "Cleaning time",
    "Unavailable": "Unavailable",
    "Blocked dates": "Blocked dates",
    "Reason": "Reason",
    "Same day checkin": "Same day checkin",
    "Checkout status": "Checkout status",
    "has-checkin": "yes",
    "has-no-checkin": "no",
    "checkout-yes": "yes",
    "checkout-no": "no",
    "Open Task List": "Open Task List",
    "Remarks": "Remarks",
    "N.A.": "N.A."
  },
  "ja": {
    "Housekeeping company": "清掃会社",
    "Housekeeper": "清掃担当者",
    "days": "日",
    "Property": "物件",
    "Room": "部屋",
    "Date": "日付",
    "Cleaning time": "開始",
    "Unavailable": "利用できません",
    "Blocked dates": "ブロック日付",
    "Reason": "理由",
    "Same day checkin": "同じの日チェクイン",
    "has-checkin": "ある",
    "has-no-checkin": "ない",
    "Checkout status": "チェックアウト",
    "checkout-yes": "済",
    "checkout-no": "未",
    "Open Task List": "タスクリストへ",
    "Remarks": "備考",
    "N.A.": "未割当"
  }
}
