<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Room Assignment') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-mobi">
            <RoomAssignmentFilter size="large" @filter="fetchData" />
          </div>
          <div class="column is-narrow">
            <div v-permission="['add-room-assignment']" class="has-text-right m-b-3x">
              <RoomAssignmentAdd :mask="true" @close="fetchData" />
            </div>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #_id="{ record }">
            {{ record._id }}
          </template>
          <template #housekeeper="{ record }">
            {{ (record.user && getUserFullname(record.user)) || '-' }}
          </template>
          <template #property="{ record }">
            {{
              record.room && record.room.property
              ? (record.room.property.abbreviation || '-') : '-'
            }}
          </template>
          <template #room="{ record }">
            {{ (record.room && record.room.name) || '-' }}
          </template>
          <template #cleaningTime="{ record }">
            {{
              `${$filters.date(record.cleaningDate, { format: $variables.dateFormat })} ${
                record.cleaningTime || ''
              }`
            }}
          </template>
          <template #status="{ record }">
            {{ $t(record.status) }}
          </template>
        </a-table>
        <Modal ref="editRoomAssignment">
          <template #default="{ hide }">
            <RoomAssignmentForm
              :value="selectedRoomAssignment"
              @close="
                () => {
                  hide();
                  fetchData();
                }
              "
              @cancel="hide"
            />
          </template>
        </Modal>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Room Assignment": "Room Assignments",
    "Filter": "Filter",
    "Housekeeper": "Housekeeper",
    "Room name": "Room name",
  },
  "ja": {
    "Room Assignment": "新規清掃の登録",
    "Filter": "フィルター",
    "Housekeeper": "清掃担当者名",
    "Room name": "部屋番号",
  }
}
</i18n>

<script>
import Modal from '@/components/Modal';
import { getFullname } from '@/utils/users';
import checkPermission from '@/utils/permission';
import list from '@/views/room-assignment/mixins/list';
import RoomAssignmentFilter from '@/views/room-assignment/components/RoomAssignmentFilter';
import RoomAssignmentAdd from '@/views/room-assignment/components/RoomAssignmentAddModal';
import RoomAssignmentForm from '@/views/room-assignment/components/RoomAssignmentForm';

export default {
  name: 'RoomAssignment',
  components: {
    RoomAssignmentFilter,
    RoomAssignmentAdd,
    Modal,
    RoomAssignmentForm,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          slots: { customRender: '_id' },
        },
        {
          title: this.$t('Housekeeper'),
          dataIndex: 'housekeeper',
          key: 'housekeeper',
          slots: { customRender: 'housekeeper' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          key: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Room name'),
          dataIndex: 'room',
          key: 'room',
          slots: { customRender: 'room' },
        },
        {
          title: this.$t('Cleaning time'),
          dataIndex: 'cleaningTime',
          key: 'cleaningTime',
          slots: { customRender: 'cleaningTime' },
        },
        {
          title: this.$t('Status'),
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
      ],
      days: 15,
      dateFrom: undefined,
      dateTo: undefined,
      partnerList: [],
      selectedRoomAssignment: undefined,
    };
  },
  computed: {
    canEditProperty() {
      return checkPermission(['modify-property']);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    rowClicked(record) {
      return {
        onClick: async () => {
          this.$store.commit('SHOW_FULLSCREEN_LOADER');

          try {
            const data = await this.$store.dispatch('room-assignments/get', { id: record.id });
            this.selectedRoomAssignment = {
              ...data,
              propertyId: data.room && data.room.propertyId,
            };

            this.$refs.editRoomAssignment.show();
          } finally {
            this.$store.commit('HIDE_FULLSCREEN_LOADER');
          }
        },
      };
    },
    getUserFullname(record) {
      return getFullname({
        fname: record.firstName,
        lname: record.lastName,
      });
    },
  },
};
</script>
