<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <div class="is-align-items-center is-flex is-justify-content-space-between mb-4">
        <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
          <IconMove v-if="draggable" width="18" height="18" />
          {{ title }}
        </h2>
        <DelRoomAssignment
          v-if="value.id"
          v-permission="['modify-room-assignment']"
          :record="value"
          @delete="$emit('close')"
        />
      </div>
      <p v-if="!value.id">
        {{ $t('Room assignment for new reservation') }}
      </p>
    </div>

    <a-form-item
      v-if="$hasPermission(['view-partner'])"
      class="column is-12"
      :label="$t('Housekeeping Company Name')"
      name="partnerId"
    >
      <InputHousekeeping
        v-model:value="form.partnerId"
        :size="size"
        :property-id="form.propertyId"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Housekeeper Name')" name="userId">
      <InputHousekeeper
        v-model:value="form.userId"
        :partner-id="form.partnerId"
        :size="size"
        :disabled="!form.partnerId"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property Name')" name="propertyId">
      <InputProperty
        v-model:value="form.propertyId"
        :partner-id="form.partnerId"
        :disabled="!form.partnerId"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Name')" name="roomId">
      <InputRoom
        v-model:value="form.roomId"
        :property-id="form.propertyId"
        :disabled="!form.propertyId"
      />
    </a-form-item>
    <transition name="view-fade">
      <div v-if="form.propertyId && form.roomId" class="column is-12">
        <RoomAssignmentCalendar :room-id="form.roomId" :property-id="form.propertyId" />
      </div>
    </transition>
    <a-form-item class="column is-6" :label="$t('Date')" name="cleaningDate">
      <a-date-picker
        v-model:value="form.cleaningDate"
        :value-format="dateFormat"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Cleaning time')" name="cleaningTime">
      <a-select v-model:value="form.cleaningTime" :placeholder="$t('Please select')" :size="size">
        <a-select-option v-for="o in timeOptions" :key="o.value" :value="o.value">
          {{ o.label }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item v-if="value && value.id" class="column is-12" :label="$t('Status')" name="status">
      <a-select v-model:value="form.status" :placeholder="$t('Please select')" :size="size">
        <a-select-option v-for="(v, k) in statusOptions" :key="k" :value="v">
          {{ $t(v) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Remarks')" name="remark">
      <a-input v-model:value="form.remarks" :size="size" :placeholder="$t('Remarks')" type="text" />
    </a-form-item>

    <div class="column is-12 m-t-4x" :class="{ 'has-text-right': value }">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Room assignment for new reservation": "Room assignment for new reservation",
    "Edit Assignment": "Edit Assignment",
    "Room Assignment Request": "Room Assignment Request",
    "Property Name": "Property Name",
    "Room Name": "Room Name",
    "Housekeeping Company Name": "Housekeeping Company Name",
    "Housekeeper Name": "Housekeeper Name",
    "Date": "Date",
    "Cleaning time": "Cleaning time",
    "Status": "Status",
    "Cancel": "Cancel",
    "Save": "Save",
    "Remarks": "Remarks",
    "housekeeping-company-required": "Housekeeping Company is required",
    "housekeeper-required": "Housekeeper is required",
    "property-required": "Property Name is required",
    "room-required": "Room Name is required",
    "date-required": "Date is required",
    "time-required": "Cleaning Time is required",
    "status-required": "Status is required",
    "Please select": "Please select",
  },
  "ja": {
    "Room assignment for new reservation": "以下の部屋に新規の予約が入りました。",
    "Edit Assignment": "清掃の割り当てを編集",
    "Room Assignment Request": "新規清掃の依頼",
    "Property Name": "物件正式名称",
    "Room Name": "部屋番号",
    "Housekeeping Company Name": "清掃会社名",
    "Housekeeper Name": "清掃担当者名",
    "Date": "日付",
    "Cleaning time": "清掃時間",
    "Status": "ステータス",
    "Cancel": "取り消し",
    "Save": "保存",
    "Remarks": "備考",
    "housekeeping-company-required": "清掃会社は必須です",
    "housekeeper-required": "清掃担当者は必須です",
    "property-required": "物件は必須です",
    "room-required": "部屋は必須です",
    "date-required": "日付は必須です",
    "time-required": "清掃時間は必須です",
    "status-required": "ステータスは必須です",
    "Please select": "選んでください",
  }
}
</i18n>

<script>
import DelRoomAssignment from '@/views/room-assignment/components/DelRoomAssignment';
import InputProperty from '@/components/InputProperty';
import InputRoom from '@/components/InputRoom';
import InputHousekeeping from '@/components/InputHousekeeping';
import InputHousekeeper from '@/components/InputHousekeeper';
import IconMove from '@/components/icons/IconMove';
import { STATUS } from '@/config/room-assignments';
import RoomAssignmentCalendar from '@/views/room-assignment/components/RoomAssignmentCalendar';

export default {
  name: 'RoomAssignmentForm',
  components: {
    DelRoomAssignment,
    RoomAssignmentCalendar,
    IconMove,
    InputHousekeeper,
    InputHousekeeping,
    InputRoom,
    InputProperty,
  },
  props: {
    draggable: {
      type: Boolean,
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['submit', 'close', 'cancel'],
  data() {
    return {
      isSubmitting: false,
      dateFormat: 'YYYY-MM-DD',
      form: {
        partnerId: undefined,
        userId: undefined,
        propertyId: undefined,
        roomId: undefined,
        cleaningDate: null,
        cleaningTime: undefined,
        status: undefined,
        remarks: undefined,
      },
      size: 'large',
      timeOptions: [
        { label: '8:00', value: '8:00' },
        { label: '8:30', value: '8:30' },
        { label: '9:00', value: '9:00' },
        { label: '9:30', value: '9:30' },
        { label: '10:00', value: '10:00' },
        { label: '10:30', value: '10:30' },
        { label: '11:00', value: '11:00' },
        { label: '11:30', value: '11:30' },
        { label: '12:00', value: '12:00' },
        { label: '12:30', value: '12:30' },
        { label: '13:00', value: '13:00' },
        { label: '13:30', value: '13:30' },
        { label: '14:00', value: '14:00' },
        { label: '14:30', value: '14:30' },
        { label: '15:00', value: '15:00' },
        { label: '15:30', value: '15:30' },
        { label: '16:00', value: '16:00' },
        { label: '16:30', value: '16:30' },
        { label: '17:00', value: '17:00' },
        { label: '17:30', value: '17:30' },
        { label: '18:00', value: '18:00' },
        { label: '18:30', value: '18:30' },
        { label: '19:00', value: '19:00' },
        { label: '19:30', value: '19:30' },
        { label: '20:00', value: '20:00' },
        { label: '20:30', value: '20:30' },
        { label: '21:00', value: '21:00' },
        { label: '21:30', value: '21:30' },
        { label: '22:00', value: '22:00' },
        { label: '22:30', value: '22:30' },
        { label: '23:00', value: '23:00' },
        { label: '23:30', value: '23:30' },
        { label: '0:00', value: '0:00' },
      ],
      statusOptions: STATUS,
    };
  },
  computed: {
    title() {
      return this.value && this.value.id
        ? this.$t('Edit Assignment')
        : this.$t('Room Assignment Request');
    },
    rules() {
      const rules = {
        partnerId: [
          // housekeeping company
          {
            type: 'number',
            required: true,
            message: this.$t('housekeeping-company-required'),
            trigger: ['change', 'blur'],
          },
        ],
        userId: [
          // housekeeper
          {
            type: 'string',
            required: true,
            message: this.$t('housekeeper-required'),
            trigger: ['change', 'blur'],
          },
        ],
        propertyId: [
          {
            type: 'string',
            required: true,
            message: this.$t('property-required'),
            trigger: ['change', 'blur'],
          },
        ],
        roomId: [
          {
            type: 'number',
            required: true,
            message: this.$t('room-required'),
            trigger: ['change', 'blur'],
          },
        ],
        cleaningDate: [
          {
            type: 'date',
            required: true,
            message: this.$t('date-required'),
            trigger: ['change', 'blur'],
          },
        ],
        cleaningTime: [
          {
            type: 'string',
            required: true,
            message: this.$t('time-required'),
            trigger: ['change', 'blur'],
          },
        ],
      };

      if (this.value && this.value.id) {
        rules.status = [
          {
            required: true,
            message: this.$t('status-required'),
            trigger: ['change', 'blur'],
          },
        ];
      }

      return rules;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            if (k in nv) {
              this.form[k] = nv[k];
              if (['partnerId', 'roomId'].includes(k)) {
                this.form[k] = parseInt(nv[k], 10);
              }
            }
          });
        }
      },
    },
  },
  created() {
    if (this.$store.getters.roles.includes('HC')) {
      this.form.partnerId = this.$store.getters.userPartner.id;
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.isSubmitting = true;
        if (this.value.id) {
          const payload = { id: this.value.id, ...this.form };

          await this.$store.dispatch('room-assignments/update', payload);
        } else {
          const payload = { ...this.form, status: STATUS.REQUESTED };

          await this.$store.dispatch('room-assignments/add', payload);
        }
        this.$emit('close', { ...this.form });
        this.isSubmitting = false;
      } catch (e) {
        this.isSubmitting = false;
        console.log(e);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
