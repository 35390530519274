<template>
  <div
    :class="`${hasAssignment ? assignment.status : ''}`"
    class="calendar-cell"
  >
    <div v-if="dateObj" class="date">
      {{ dateObj.format('DD') }}
    </div>
    <!--    guest start -->
    <div
      v-if="hasGuest"
      class="hasReservationInfo small-block"
      :class="`
          ${hasGuest && 'hasGuest booked'}
          ${isGuestFirstDate && 'radius-left'}
          ${isGuestLastDate && 'radius-right'}
          ${position === 0 && !isGuestFirstDate && !isGuestLastDate && 'non-overlap-block-start'}
          ${
        position === 0 &&
        isGuestLastDate &&
        !isGuestFirstDate &&
        'non-overlap-block-start-for-last-date'
      }
          ${position === lastPosition && 'non-overlap-block-end'}
          ${isGuestCheckoutDate && 'is-guest-checkout-date'}
        `"
    >
      <div
        v-if="isGuestFirstDate || position === 0"
        class="has-text-weight-bold is-size-8 small-block-text"
        :title="guestName"
      >
        {{ $filters.truncate(guestName, 20) }}
      </div>
    </div>
    <template v-if="!!checkoutGuest">
      <div
        class="checkoutGuest
        hasReservationInfo
        is-cursor-pointer
        small-block
        hasGuest
        booked
        radius-right"
      >
        <div
          class="has-text-weight-bold is-size-8 small-block-text"
          :title="checkoutGuestName"
        >
          {{ $filters.truncate(checkoutGuestName, 20) }}
        </div>
      </div>
    </template>
    <!-- guest end    -->

    <!--    blocked date start -->
    <div
      v-if="hasBlockedDate || hasBlockedDateBeforeDateFrom"
      class="hasReservationInfo small-block"
      :class="`
          ${hasBlockedDate && 'hasBlockedDate unavailable'}
          ${isUnavailableStart && !hasBlockedDateBeforeDateFrom && 'radius-left'}
          ${isUnavailableEnd && position !== lastPosition && 'radius-right'}
          ${position === 0 && !isUnavailableStart && 'non-overlap-block-start'}
          ${position === lastPosition && 'non-overlap-block-end'}
          ${
        hasBlockedDateBeforeDateFrom &&
        'hasBlockedDate half-block non-overlap-block-start radius-right unavailable'
      }
        `"
    >
      <div
        v-show="isUnavailableTextShowed || position === 0"
        :class="['has-text-white', 'has-text-weight-bold', 'is-size-8', 'no-wrap-text']"
        :title="$t('Unavailable')"
      >
        {{ $t('Unavailable') }}
      </div>
    </div>
    <!--    blocked date end -->

    <!--    Assignment start -->
    <div v-if="hasAssignment" class="assignment-summary has-text-black">
      <div
        :class="['assignment-summary-title', 'has-text-weight-bold', 'is-size-8', 'no-wrap-text']"
        :title="housekeeperName"
      >
        {{ housekeeperName }}
      </div>
    </div>
    <!--    Assignment end -->
  </div>
</template>

<i18n src="@/locales/components/calendar-cell.json"></i18n>

<script>
import CalendarCellComputed from '@/components/mixins/CalendarCellComputed';

export default {
  name: 'RoomAssignmentCalendarCell',
  mixins: [CalendarCellComputed],
  props: {
    date: {
      type: String,
      required: true,
    },
    dateObj: {
      type: Object, // moment obj
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      default: 0,
    },
    lastPosition: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style src="@/assets/css/calendar-cell.scss" lang="scss" scoped></style>
