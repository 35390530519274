<template>
  <Delete
    action-handler="room-assignments/delete"
    :payload-value="{ id: record.id }"
    :title="$t('title')"
    :description="$t('desc', { name: record.name })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.name })"
    @close="$emit('delete')"
  >
    <template #handler="{ show }">
      <slot name="handler" :show="show" />
    </template>
  </Delete>
</template>

<i18n>
{
"en": {
"title": "Delete assignment?",
"desc": "Are you sure you want to delete assignment ?",
"deleted-title": "Assignment is deleted",
"deleted-desc": "Assignment has been removed."
},
"ja": {
"title": "新規清掃の登録削除?",
"desc": "この部屋の新規清掃の登録本当に削除しますか？",
"deleted-title": "清掃員の割り当ての削除完了",
"deleted-desc": "清掃員の割り当てが削除されました"
}
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteRoomAssignment',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
